import { useEffect, useState } from "react";
import getBaseUrl from "../../lib/utils/getBaseUrl";
import Fundamentei from "../branding/Fundamentei";
import * as palette from "../palette";

export default function WholePageLoadingBlankslate() {
  const [tryToRefreshManually, setShowTryToRefreshManually] = useState(false);

  useEffect(() => {
    const tryToRefreshManuallyTimeout = setTimeout(() => setShowTryToRefreshManually(true), 6000);
    return () => clearTimeout(tryToRefreshManuallyTimeout);
  }, []);

  return (
    <>
      <style jsx={true} global={true}>
        {`
          html,
          body {
            height: 100%;
          }
        `}
      </style>

      <div
        className="flex h-full w-full items-center justify-center"
        style={{
          backgroundColor: palette.selago,
        }}
      >
        <div className="grid gap-y-5 text-center">
          <div>
            <Fundamentei width={106} height={101} fill={palette.royalBlue} />
          </div>

          <h1 className="m-0 font-bold text-blue-600">{tryToRefreshManually ? "Aguarde :(" : "Aguarde..."}</h1>

          {tryToRefreshManually && (
            <p
              className="m-0 text-base font-medium tracking-wider"
              style={{
                color: palette.blueBayoux,
              }}
            >
              {"Investidor—caso essa página permaneça por muito tempo, "}
              <a
                title="Atualizar página"
                href={getBaseUrl()}
                className="underline"
                style={{
                  color: palette.link,
                }}
              >
                tente atualizar manualmente.
              </a>
            </p>
          )}
        </div>
      </div>
    </>
  );
}
