import notUndefinedOrNull from "lib/utils/notUndefinedOrNull";
import Link from "next/link";
import type { IntlShape } from "react-intl";
import { FormattedMessage } from "react-intl";

export default function getPlatformFAQs(intl: IntlShape) {
  return [
    {
      question: intl.formatMessage({
        defaultMessage: "Vocês disponibilizam um período de testes sem custo?",
        id: "/4F7tZ",
      }),
      answer: [
        intl.formatMessage({
          defaultMessage: "Sim! Você pode ter acesso completo ao plano Premium por até 7 dias sem custo.",
          id: "Rd64kv",
        }),
        intl.formatMessage({
          defaultMessage:
            "O processo é muito simples: você assina normalmente como se fosse pagar o plano com o seu cartão de crédito (Visa ou Mastercard), preenche os dados do seu cartão e sua assinatura será liberada, mas nenhuma cobrança será feita em até 7 dias.",
          id: "3XHj1L",
        }),
        intl.formatMessage({
          defaultMessage:
            "Se você decidir permanecer conosco depois desse prazo, a cobrança será feita automaticamente, até lá você pode testar tudo o que oferecemos, mas caso não queira permanecer, basta cancelar, simples assim.",
          id: "pOvykf",
        }),
      ],
    },
    {
      question: intl.formatMessage({
        defaultMessage: "Com qual frequência os dados são atualizados?",
        id: "x201DT",
      }),
      answer: [
        intl.formatMessage({
          defaultMessage: "Atualizamos todas as informações diariamente.",
          id: "EEfNi6",
        }),
        intl.formatMessage({
          defaultMessage:
            "No caso dos ativos do exterior, existe uma diferença entre a data de divulgação dos balanços no site de RI (releases) e a divulgação dos balanços oficiais na SEC (10-Q e 10-K).",
          id: "gG8tQP",
        }),
        intl.formatMessage({
          defaultMessage:
            "A Morningstar retira os dados somente da SEC, por isso pode haver um atraso entre a data que você olha na agenda e a atualização da tabela no Fundamentei.",
          id: "vR2cE8",
        }),
        intl.formatMessage({
          defaultMessage:
            "Essa diferença pode ser de dias ou até semanas, mas não é culpa nem nossa e nem da Morningstar, dependemos da própria empresa divulgar na SEC.",
          id: "KHbvIt",
        }),
      ],
    },
    {
      question: intl.formatMessage({
        defaultMessage: "Como funciona se eu quiser cancelar a assinatura? Tem reembolso?",
        id: "+Er73K",
      }),
      answer: [
        intl.formatMessage({
          defaultMessage:
            'Você pode cancelar sua assinatura quando quiser, sem precisar entrar em contato conosco: basta ir no menu, clicar em "Conta" e depois "Gerenciar Assinatura", vai ter um botão de "Cancelar Assinatura", clicando nele seu plano estará cancelado, ou seja, é um processo de poucos segundos.',
          id: "SExyQ+",
        }),
        intl.formatMessage({
          defaultMessage:
            "Não oferecemos reembolsos, ainda mais que disponibilizamos um período de testes sem custo para você ter certeza do que está recebendo. Seu acesso Premium continuará até o fim do período e nenhuma cobrança nova será feita no seu cartão, não se preocupe.",
          id: "J7QdIr",
        }),
      ],
    },
    {
      question: intl.formatMessage({
        defaultMessage: "De onde vocês tiram as informações? Posso confiar?",
        id: "bc+TOJ",
      }),
      answer: [
        intl.locale === "pt-BR"
          ? intl.formatMessage({
              defaultMessage:
                "Os dados dos ativos brasileiros são retirados da B3, da CVM e, em alguns casos, dos relatórios (releases) divulgados pelas próprias empresas, ou seja, sempre de fontes oficiais, já os dados de cotações utilizamos a API da Enfoque, que para nós, é a mais confiável do Brasil.",
              id: "aWsu1T",
            })
          : null,
        intl.formatMessage({
          defaultMessage:
            "No caso dos ativos do exterior nós utilizamos a API da Morningstar, cujo os dados são retirados da SEC e dos relatórios (releases) dos ativos, todas fontes oficiais também.",
          id: "jLCDrG",
        }),
        intl.formatMessage({
          defaultMessage:
            "Os KPIs das Stocks são fornecidos pela FinChat, que pega os dados dos releases, apresentações, 10-Q e 10-K das empresas.",
          id: "zr4t+U",
        }),
        intl.formatMessage({
          defaultMessage:
            "Caso você veja alguma informação divergente, não deixe de entrar em contato conosco, nos preocupamos sempre em manter os dados mais assertivos no site.",
          id: "ed8wQK",
        }),
      ].filter(notUndefinedOrNull),
    },

    {
      question: intl.formatMessage({
        defaultMessage: "Consigo baixar os dados das empresas para Excel? Vocês tem API?",
        id: "bMp1JO",
      }),
      answer: [
        <FormattedMessage
          defaultMessage="Não e não. A maioria das informações são licenciadas e/ou proprietárias sendo proibido o download das mesmas (ainda que de forma automatizada), sob pena de risco jurídico. Veja aqui a nossa <a>Política Anti-Scraping.</a>"
          id="fFolpO"
          values={{
            a: (text) => (
              <Link className="text-blue-600 underline" href="https://fundamentei.com/anti-scraping">
                {text}
              </Link>
            ),
          }}
        />,
        intl.formatMessage({
          defaultMessage:
            "Temos planos de no futuro disponibilizar um botão de download dos dados e uma API para quem desejar trabalhar com os dados fora do site, mas por enquanto não previsão para isso.",
          id: "AJF6mp",
        }),
      ],
    },
  ];
}
