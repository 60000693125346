"use client";

import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import classNames from "components/ui/classNames";
import { ChevronDownIcon } from "lucide-react";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={classNames("border-b border-b-slate-200 last-of-type:border-none dark:border-b-slate-700", className)}
    {...props}
  />
));

AccordionItem.displayName = "AccordionItem";

interface AccordionTriggerProps extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> {
  hasChevronIcon?: boolean;
}

const AccordionTrigger = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Trigger>, AccordionTriggerProps>(
  ({ className, children, hasChevronIcon = true, ...props }, ref) => (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={classNames(
          "group flex flex-1 items-center justify-between py-4 font-medium transition-all",
          {
            "[&[data-state=open]>div>svg]:rotate-180": hasChevronIcon,
          },
          className,
        )}
        {...props}
      >
        {children}

        {hasChevronIcon && (
          <div
            className={classNames(
              "flex min-w-10 items-center justify-center whitespace-nowrap rounded-full bg-slate-100 text-slate-600",
              "border border-transparent group-hover:bg-violet-100 group-hover:text-violet-600",
              "h-10 w-10",
            )}
          >
            <ChevronDownIcon
              strokeWidth={2}
              absoluteStrokeWidth={true}
              className="h-5 w-5 stroke-2 text-inherit transition-transform duration-200"
            />
          </div>
        )}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  ),
);

AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={classNames(
      "overflow-hidden text-sm transition-all",
      "data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      className,
    )}
    {...props}
  >
    <div className="pb-4 pt-0">{children}</div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
