import getBaseUrl from "lib/utils/getBaseUrl";
import { format } from "util";

export default function getTestimonials(isUsLanguage: boolean) {
  if (isUsLanguage) {
    return [
      {
        name: "Djeison Schlatter",
        jobTitle: "Industrial Manager",
        company: "",
        socialMedia: {
          label: "djeison.schlatter",
          href: "https://www.facebook.com/djeison.schlatter",
        },
        testimonial:
          "Until 2018, I was completely unfamiliar with the stock market, but after I discovered Eduardo, it became the foundation I needed to start! All the information is presented on the website in a concise and clear way. Without Fundamentei, my diversified long-term portfolio wouldn’t exist!",
        avatar: format("%s/static/landing/testimonials/djeisonsc.jpeg", getBaseUrl()),
      },
      {
        name: "Willem Costa",
        jobTitle: "Graphic Design Technician",
        company: "MR Copiadoras Digitais",
        socialMedia: {
          label: "@willemcp",
          href: "https://www.instagram.com/willemcp/",
        },
        testimonial:
          "For an investor, Fundamentei is the most complete tool to understand EVERYTHING about a company. You can access all the information in one place. Paying for Fundamentei is as important as paying for electricity, water, and internet—it’s become a fixed cost.",
        avatar: format("%s/static/landing/testimonials/willemcosta.jpeg", getBaseUrl()),
      },
      {
        name: "Danilo Zucato",
        socialMedia: {
          label: "@danzucato",
          href: "https://www.instagram.com/danzucato",
        },
        jobTitle: "Performance Media Analyst",
        company: "Media K",
        testimonial:
          "Fundamentei has been helping me invest since 2020. It guides me through my analyses and helps me discover valuable assets. Honestly, without it, I’d be way behind in building my portfolio, as it’s the only way I can easily track around 80 stocks without headaches.",
        avatar: format("%s/static/landing/testimonials/danilozrobert.jpeg", getBaseUrl()),
      },
      {
        name: "Ciro Thomé",
        socialMedia: {
          label: "ciro_thome",
          href: "https://br.linkedin.com/in/ciro-thome",
        },
        jobTitle: "Intern",
        company: "Navarro Prado, Nefussi Mandel & Santos Silva Advogados",
        testimonial:
          "Fundamentei opened my eyes to avoid amateur mistakes and saved me from almost certainly losing the savings I worked years to build. Today, it’s an essential tool for tracking the companies I invest in with a long-term focus.",
        avatar: format("%s/static/landing/testimonials/cirotq.jpeg", getBaseUrl()),
      },
      {
        name: "Lucas Franzoni",
        socialMedia: {
          label: "@lfranzoni",
          href: "https://www.instagram.com/lfranzoni",
        },
        jobTitle: "Administrator",
        company: "Rastro Transportes",
        testimonial:
          "Analyzing stocks used to be grueling: hunting for data, navigating investor relations sites, reading reports, calculating… Fundamentei is excellent! It offers a community full of compiled and updated information that’s easy to access and navigate, speeding up investment decisions.",
        avatar: format("%s/static/landing/testimonials/lucsfranzoni.jpeg", getBaseUrl()),
      },
      {
        name: "Victor Moreschi",
        socialMedia: {
          label: "@victorsottomaior",
          href: "https://www.instagram.com/victorsottomaior",
        },
        jobTitle: "M&A Specialist",
        company: "Koliver Merchant Banking",
        testimonial:
          "I still remember the day I found Fundamentei: it was a relief to finally find rational, logical content about stocks. I confess I’ve tried other platforms about three times, but once you know Fundamentei, it’s impossible to switch to something else.",
        avatar: format("%s/static/landing/testimonials/vmoreschi7.jpeg", getBaseUrl()),
      },
      {
        name: "Luiz Cezer",
        socialMedia: {
          label: "@lcezermf",
          href: "https://www.instagram.com/lcezermf",
        },
        jobTitle: "Software Developer (Elixir)",
        company: "Mindful Care (USA)",
        testimonial:
          "It's impressive to see the evolution of the site and content. I've been following since 2018 (when it had a different domain), and everything has improved a lot since then. Today, it's my main site for keeping up with releases and new information about my assets! Congratulations!",
        avatar: format("%s/static/landing/testimonials/lccezinha.jpeg", getBaseUrl()),
      },
      {
        name: "Felipe Nunes",
        socialMedia: {
          label: "@felipenunes.pe",
          href: "https://www.instagram.com/felipenunes.pe",
        },
        jobTitle: "Refrigeration Technician",
        company: "Pajeú",
        testimonial:
          "I don't work with finance, and with the day-to-day rush, sometimes we have little time to study a company. But with Fundamentei, I can perform a practical, reliable analysis without wasting time.",
        avatar: format("%s/static/landing/testimonials/felipenunes.jpeg", getBaseUrl()),
      },
      {
        name: "Fernando Rocha",
        socialMedia: {
          label: "@fhc.rocha",
          href: "https://www.instagram.com/fhc.rocha",
        },
        jobTitle: "Dentist",
        company: "",
        testimonial:
          "Fundamentei is the best fundamental analysis site I've found on the market because it has easy-to-interpret and visual information, plus the financial summaries and discussions with other subscribers make the analysis even easier and faster.",
        avatar: format("%s/static/landing/testimonials/fernando_unifalmg.jpeg", getBaseUrl()),
      },
      {
        name: "Alexandre Ferreira",
        socialMedia: {
          label: "@alexandre_afs2",
          href: "https://www.instagram.com/alexandre_afs2",
        },
        jobTitle: "Systems Analyst",
        company: "Governo",
        testimonial:
          "Fundamentei was essential for my investments. The site's information and Eduardo's analysis gave me the foundation for my decisions. My portfolio not only outperformed the CDI and all indexes but also guaranteed returns above inflation.",
        avatar: format("%s/static/landing/testimonials/alexandreee.png", getBaseUrl()),
      },
      {
        name: "Francisco José",
        socialMedia: {
          label: "@franze.rocha",
          href: "https://www.instagram.com/franze.rocha",
        },
        jobTitle: "Public Servant",
        company: "Governo",
        testimonial:
          "Discovering Fundamentei opened the black box in my beginner investor mind because I gained access to relevant, clear, and objective information that shines a light on the idea of simplification—being a partner in good companies and living peacefully with it.",
        avatar: format("%s/static/landing/testimonials/franzepereira.jpeg", getBaseUrl()),
      },
      {
        name: "Gabrielle Moreira",
        socialMedia: {
          label: "@gabbymoreirarocha",
          href: "https://www.instagram.com/gabbymoreirarocha",
        },
        jobTitle: "Investor",
        company: "",
        testimonial:
          "To invest in good companies, you need to know them well, and doing that through Fundamentei makes everything much simpler and more effective. Intuitive interface, constant updates, and detailed data—all of this greatly facilitates decision-making.",
        avatar: format("%s/static/landing/testimonials/gabriellemrocha.jpeg", getBaseUrl()),
      },
      {
        name: "Leonardo Corso",
        socialMedia: {
          label: "leonardo-corso-9a205425",
          href: "https://www.linkedin.com/in/leonardo-corso-9a205425/",
        },
        jobTitle: "Product Manager",
        company: "Royal De Heus",
        testimonial:
          "I discovered Eduardo in 2018, watching his 20-second analyses! Haha. I follow the Buy & Hold strategy, using Fundamentei as a guide to select and track the best assets. The data available really helps me invest with peace of mind and great results.",
        avatar: format("%s/static/landing/testimonials/lbcorso.jpeg", getBaseUrl()),
      },
      {
        name: "Vitor Hugo",
        socialMedia: {
          label: "@vitorhugog.silva",
          href: "https://www.instagram.com/vitorhugog.silva",
        },
        jobTitle: "Electrical Engineer",
        company: "",
        testimonial:
          "Structured and focused on a long-term vision, Fundamentei is essential for all investors. It helped structure my portfolio with good assets through simplified business analysis and an enriching discussion forum.",
        avatar: format("%s/static/landing/testimonials/vitorhgs.jpg", getBaseUrl()),
      },
      {
        name: "Yuri Barros",
        socialMedia: {
          label: "@yn.cardoso",
          href: "https://www.instagram.com/yn.cardoso",
        },
        jobTitle: "City Attorney - São Paulo",
        company: "Governo",
        testimonial:
          // NOTE(Andrey): Revisar "sardinha"
          "In a speculative sea full of small-time investors, Fundamentei reveals the coordinates so that our fundamental analysis becomes less torturous. I'm grateful to have it as my companion for the long term.",
        avatar: format("%s/static/landing/testimonials/yurineodebarros.jpeg", getBaseUrl()),
      },
      {
        name: "Daniel Cardoso",
        socialMedia: {
          label: "Danferc10",
          href: "https://x.com/Danferc10/",
        },
        jobTitle: "Lawyer",
        company: "",
        testimonial:
          "I've been a subscriber since 2018. With the new site, featuring a cleaner, bolder design and continuous improvements, I became a Premium/Black user. It's by far the best site for stock market research in Brazil and abroad.",
        avatar: format("%s/static/landing/testimonials/danferc.jpg", getBaseUrl()),
      },
      {
        name: "Thiago Ferreira",
        socialMedia: {
          label: "@thiagaojf1982",
          href: "https://www.instagram.com/thiagaojf1982",
        },
        jobTitle: "Investor",
        company: "",
        testimonial:
          // NOTE(Andrey): Revisado
          "I've been following Eduardo for some time now through open courses and Instagram. I had the opportunity to take the last two versions of the Caixa Preta course, and I'm a Fundamentei subscriber. It helped me build an American portfolio and better shape my Brazilian one.",
      },
      {
        name: "Aline Gonçalves",
        socialMedia: {
          label: "@byalinegoncalves",
          href: "https://www.instagram.com/byalinegoncalves",
        },
        jobTitle: "External Auditor and High-Level Sustainability Mentor",
        company: "",
        testimonial:
          "Fundamentei is exceptional for a strategic investment approach! It supports me both in monitoring and continuous learning, making everything intuitive. A beacon for sustainable and data-driven choices? Absolutely approved!",
        avatar: format("%s/static/landing/testimonials/alinegsantoss.jpeg", getBaseUrl()),
      },
      {
        name: "Alessandro Monteiro",
        socialMedia: {
          label: "@amcavalcante__",
          href: "https://www.instagram.com/amcavalcante__",
        },
        jobTitle: "Federal Public Servant",
        company: "Governo",
        testimonial:
          "Fundamentei has been helping me invest since 2020. It guides me through my analyses and helps me discover valuable assets. Honestly, without it, I’d be way behind in building my portfolio, as it’s the only way I can easily track around 80 stocks without headaches.",
        avatar: format("%s/static/landing/testimonials/amcavalcantefa7.png", getBaseUrl()),
      },
      {
        name: "Daniel Pedrazi",
        socialMedia: {
          label: "@daniel.dvp ",
          href: "https://www.instagram.com/daniel.dvp ",
        },
        jobTitle: "Forensic Expert",
        company: "Polícia Científica de SP",
        testimonial:
          "You know that place where you can find, in a simple and summarized way, all the important information about companies listed on the stock exchange? That’s Fundamentei. It gives us a complete overview of the company's health, allowing us to make the best decisions!",
        avatar: format("%s/static/landing/testimonials/danielvp199.jpeg", getBaseUrl()),
      },
      {
        name: "Fernando Assunção",
        socialMedia: {
          label: "@drfernandocesarassucao",
          href: "https://www.instagram.com/drfernandocesarassucao",
        },
        jobTitle: "Technology and Innovation Director",
        company: "Mais 60 Saúde",
        testimonial:
          "Fundamentei is excellent! With easy access to company analysis and financial data, it provides everything I need to make informed decisions and invest confidently in the stock market. The site is a great tool for anyone looking to invest!",
        avatar: format("%s/static/landing/testimonials/fernandonefro.jpeg", getBaseUrl()),
      },
      {
        name: "Fernando Fridlund",
        socialMedia: {
          label: "fernando.fridlund",
          href: "https://www.facebook.com/fernando.fridlund/",
        },
        jobTitle: "Geologist",
        company: "Petrobras",
        testimonial:
          "Fundamentei is an exceptional tool even for beginner users, where we learn to identify and measure the fundamentals of each company we are interested in. Fundamentei teaches us how easy it is to operate in the stock market.",
        avatar: format("%s/static/landing/testimonials/fridlund.png", getBaseUrl()),
      },
      {
        name: "Giovanni Petris",
        socialMedia: {
          label: "@giopetris",
          href: "https://www.instagram.com/giopetris",
        },
        jobTitle: "Software Developer",
        company: "Beech Valley",
        testimonial:
          "Fundamentei is essential for my investment decisions. The site simplifies asset analysis, offers exclusive information, and has incredible video content and community discussions. Without a doubt, it’s the best site for long-term investors.",
        avatar: format("%s/static/landing/testimonials/giopetris.jpeg", getBaseUrl()),
      },
      {
        name: "Guilherme Gindri",
        socialMedia: {
          label: "@guilhermegindri",
          href: "https://www.instagram.com/guilhermegindri",
        },
        jobTitle: "Electrical Engineer",
        company: "-",
        testimonial:
          "Fundamentei cleared the fog that hung over the stock market for me. The complete platform and video analyses allow us to have independence when it comes to investing.",
        avatar: format("%s/static/landing/testimonials/guilher_me_tal.jpeg", getBaseUrl()),
      },
      {
        name: "Humberto Faria",
        socialMedia: {
          label: "@humbertodonisetede",
          href: "https://www.instagram.com/humbertodonisetede",
        },
        jobTitle: "Retiree",
        company: "Cemig",
        testimonial:
          "After three years of struggling, dealing with insecurity, doubts, difficulties in analysis, and a lot of tension, I discovered Fundamentei. It changed everything because it was exactly what I needed: to invest with peace of mind, no stress, and a lot of confidence and joy.",
        avatar: format("%s/static/landing/testimonials/hdf1967.jpeg", getBaseUrl()),
      },
      {
        name: "João Marcos",
        socialMedia: {
          label: "@joaomarcosdefigueiredo",
          href: "https://www.instagram.com/joaomarcosdefigueiredo",
        },
        jobTitle: "Doctor",
        company: "",
        testimonial:
          "I've been a Fundamentei subscriber for years, and I can say it’s the best thing that happened to me as an investor. A serious approach to investments without sensationalism, focused on acquiring knowledge and investing for the long term. Thank you!",
      },
      {
        name: "Leandro Soares",
        socialMedia: {
          label: "@leandro_silva0508",
          href: "https://www.instagram.com/leandro_silva0508",
        },
        jobTitle: "Flight Attendant",
        company: "Azul",
        testimonial:
          "My experience with Fundamentei transformed the way I invest! With a detailed layout and precise insights, I now make safer and smarter decisions. Thank you, Fundamentei team, for making my investment evaluation process so much easier!",
        avatar: format("%s/static/landing/testimonials/leandro.jpeg", getBaseUrl()),
      },
      {
        name: "Matheus Lima",
        socialMedia: {
          label: "@mrdlima",
          href: "https://www.instagram.com/mrdlima",
        },
        jobTitle: "Sales Engineer",
        company: "Weg",
        testimonial:
          "Fundamentei is an excellent platform that provides valuable and up-to-date information on various assets in the stock market. Its main differentiators are the diversity of data and the visual practicality in how it is presented.",
        avatar: format("%s/static/landing/testimonials/m.lima2509.png", getBaseUrl()),
      },
      {
        name: "Vinícius Marzo",
        socialMedia: {
          label: "@serenittapfp",
          href: "https://www.instagram.com/serenittapfp",
        },
        jobTitle: "Financial Planner",
        company: "Serenittà",
        testimonial:
          "I'm a personal financial planner at Serenittà, and I have been a Fundamentei subscriber since 2019. The team is serious and competent, providing us with technical and fundamental information on each company's financial statements.",
        avatar: format("%s/static/landing/testimonials/vinicius.jpeg", getBaseUrl()),
      },
    ];
  }
  return [
    {
      name: "Djeison Schlatter",
      jobTitle: "Gerente Industrial",
      company: "",
      socialMedia: {
        label: "djeison.schlatter",
        href: "https://www.facebook.com/djeison.schlatter",
      },
      testimonial:
        "Até 2018 eu estava alheio à renda variável até que conheci o Eduardo, foi o alicerce que precisava para iniciar! Todas as informações estão no site de forma concisa e clara. Sem o Fundamentei a minha carteira diversificada de longo prazo não existiria!",
      avatar: format("%s/static/landing/testimonials/djeisonsc.jpeg", getBaseUrl()),
    },
    {
      name: "Willem Costa",
      jobTitle: "Técnico - Design Gráfico",
      company: "MR Copiadoras Digitais",
      socialMedia: {
        label: "@willemcp",
        href: "https://www.instagram.com/willemcp",
      },
      testimonial:
        "Para um investidor o Fundamentei é a ferramenta mais completa para entender TUDO da empresa. Você consegue ter todas as informações em um só lugar. Pagar o Fundamentei é tão importante como uma conta de luz, água e internet, já faz parte do custo fixo.",
      avatar: format("%s/static/landing/testimonials/willemcosta.jpeg", getBaseUrl()),
    },
    {
      name: "Danilo Zucato",
      socialMedia: {
        label: "@danzucato",
        href: "https://www.instagram.com/danzucato",
      },
      jobTitle: "Analista de Mídias de Performance",
      company: "Media K",
      testimonial:
        "O Fundamentei me auxilia a investir desde 2020. Ele me norteia nas análises e descobertas de ativos de valor. Confesso que sem ele, estaria bem atrasado na montagem de minha carteira, pois só assim posso acompanhar cerca de 80 ativos sem dor de cabeça.",
      avatar: format("%s/static/landing/testimonials/danilozrobert.jpeg", getBaseUrl()),
    },
    {
      name: "Ciro Thomé",
      socialMedia: {
        label: "ciro_thome",
        href: "https://br.linkedin.com/in/ciro-thome",
      },
      jobTitle: "Estagiário",
      company: "Navarro Prado, Nefussi Mandel & Santos Silva Advogados",
      testimonial:
        "O Fundamentei me abriu os olhos para a sardinhagem e evitou a perda quase certa da reserva que conquistei com anos de trabalho duro. Hoje, é uma ferramenta essencial para o acompanhamento das empresas em que invisto com foco no longo prazo.",
      avatar: format("%s/static/landing/testimonials/cirotq.jpeg", getBaseUrl()),
    },
    {
      name: "Lucas Franzoni",
      socialMedia: {
        label: "@lfranzoni",
        href: "https://www.instagram.com/lfranzoni",
      },
      jobTitle: "Administrador",
      company: "Rastro Transportes",
      testimonial:
        "Analisar ativos era árduo: caçar dados, sites de RI, ler release, DFP, 10-K inteiro, calcular... O Fundamentei é excelente! Uma comunidade com muita informação compilada e atualizada de fácil acesso para filtrar e navegar, agilizando decisões de investimento.",
      avatar: format("%s/static/landing/testimonials/lucsfranzoni.jpeg", getBaseUrl()),
    },
    {
      name: "Victor Moreschi",
      socialMedia: {
        label: "@victorsottomaior",
        href: "https://www.instagram.com/victorsottomaior",
      },
      jobTitle: "M&A",
      company: "Koliver Merchant Banking",
      testimonial:
        "Lembro até hoje do dia em que encontrei o Fundamentei: foi um alívio encontrar um conteúdo racional e com lógica sobre ações. Confesso que já tentei usar outras plataformas umas 3x, mas é impossível se acostumar a outra após conhecer o Fundamentei.",
      avatar: format("%s/static/landing/testimonials/vmoreschi7.jpeg", getBaseUrl()),
    },
    {
      name: "Luiz Cezer",
      socialMedia: {
        label: "@lcezermf",
        href: "https://www.instagram.com/lcezermf",
      },
      jobTitle: "Desenvolvedor de Software (Elixir)",
      company: "Mindful Care (USA)",
      testimonial:
        "Impressionante ver a evolução do site e conteúdo. Acompanho desde 2018 (na época outro domínio) e de lá pra cá tudo melhorou muito. Hoje é meu principal site para acompanhar os releases e novas informações sobre meus ativos! Parabéns!",
      avatar: format("%s/static/landing/testimonials/lccezinha.jpeg", getBaseUrl()),
    },
    {
      name: "Felipe Nunes",
      socialMedia: {
        label: "@felipenunes.pe",
        href: "https://www.instagram.com/felipenunes.pe",
      },
      jobTitle: "Técnico de Refrigeração",
      company: "Pajeú",
      testimonial:
        "Não sou da área de finanças e com a correria do dia a dia às vezes temos pouco tempo para estudar uma empresa, mas com o Fundamentei posso realizar um análise prática, confiável e sem perder tempo.",
      avatar: format("%s/static/landing/testimonials/felipenunes.jpeg", getBaseUrl()),
    },
    {
      name: "Fernando Rocha",
      socialMedia: {
        label: "@fhc.rocha",
        href: "https://www.instagram.com/fhc.rocha",
      },
      jobTitle: "Dentista",
      company: "",
      testimonial:
        "O Fundamentei é o melhor site de análise fundamentalista que já encontrei no mercado porque tem as informações muito fáceis e lúdicas de serem interpretadas, além de ter os resumos financeiros e discussões dos outros assinantes que torna a análise ainda mais fácil e rápida.",
      avatar: format("%s/static/landing/testimonials/fernando_unifalmg.jpeg", getBaseUrl()),
    },
    {
      name: "Alexandre Ferreira",
      socialMedia: {
        label: "@alexandre_afs2",
        href: "https://www.instagram.com/alexandre_afs2",
      },
      jobTitle: "Analista de Sistemas",
      company: "Governo",
      testimonial:
        "O Fundamentei foi essencial para os meus investimentos. As informações do site e as análises do Eduardo me deram base para as minhas decisões. Minha carteira não só superou o CDI e todos os índices, mas também garantiu retornos acima da inflação.",
      avatar: format("%s/static/landing/testimonials/alexandreee.png", getBaseUrl()),
    },
    {
      name: "Francisco José",
      socialMedia: {
        label: "@franze.rocha",
        href: "https://www.instagram.com/franze.rocha",
      },
      jobTitle: "Servidor Público",
      company: "Governo",
      testimonial:
        "Descobrir o Fundamentei abriu a caixa-preta da minha mente de investidor iniciante, pois tive acesso a informações relevantes, claras, objetivas e que jogam luz na ideia de simplificação, de ser sócio de boas empresas e viver tranquilo com isso.",
      avatar: format("%s/static/landing/testimonials/franzepereira.jpeg", getBaseUrl()),
    },
    {
      name: "Gabrielle Moreira",
      socialMedia: {
        label: "@gabbymoreirarocha",
        href: "https://www.instagram.com/gabbymoreirarocha",
      },
      jobTitle: "",
      company: "",
      testimonial:
        "Para investir em boas empresas é preciso conhecê-las bem e fazer isso através do Fundamentei torna tudo muito mais simples e assertivo. Interface intuitiva, atualizações constantes e dados detalhados, tudo isso facilita demais a tomada de decisão.",
      avatar: format("%s/static/landing/testimonials/gabriellemrocha.jpeg", getBaseUrl()),
    },
    {
      name: "Leonardo Corso",
      socialMedia: {
        label: "leonardo-corso-9a205425",
        href: "https://www.linkedin.com/in/leonardo-corso-9a205425/",
      },
      jobTitle: "Gerente de Produto",
      company: "Royal De Heus",
      testimonial:
        "Conheci o Eduardo em 2018, assistindo as análises de 20 segundos! rsrs Aplico a estratégia B&H, usando o site Fundamentei como guia para selecionar e acompanhar os melhores ativos. Os dados disponíveis ajudam muito a investir com tranquilidade e bons resultados.",
      avatar: format("%s/static/landing/testimonials/lbcorso.jpeg", getBaseUrl()),
    },
    {
      name: "Vitor Hugo",
      socialMedia: {
        label: "@vitorhugog.silva",
        href: "https://www.instagram.com/vitorhugog.silva",
      },
      jobTitle: "Engenheiro Eletricista",
      company: "",
      testimonial:
        "Estruturado e focado na visão de longo prazo, o Fundamentei é essencial para todos os investidores. Auxiliou a estruturação da minha carteira com bons ativos através da análise facilitada dos negócios e um fórum de discussões enriquecedor.",
      avatar: format("%s/static/landing/testimonials/vitorhgs.jpg", getBaseUrl()),
    },
    {
      name: "Yuri Barros",
      socialMedia: {
        label: "@yn.cardoso",
        href: "https://www.instagram.com/yn.cardoso",
      },
      jobTitle: "Procurador do Município SP",
      company: "Governo",
      testimonial:
        "Em um mar especulativo, cheio de sardinhas, o Fundamentei revela as coordenadas para que nossas análises fundamentalistas sejam menos tortuosas. Sou grato por tê-lo como meu companheiro para o longo prazo.",
      avatar: format("%s/static/landing/testimonials/yurineodebarros.jpeg", getBaseUrl()),
    },
    {
      name: "Daniel Cardoso",
      socialMedia: {
        label: "Danferc10",
        href: "https://x.com/Danferc10/",
      },
      jobTitle: "Advogado",
      company: "",
      testimonial:
        "Sou inscrito desde 2018. Com o novo site, com design mais clean, arrojado e melhoria contínua, tornei-me Premium/Black. É disparado o melhor site para consultas de Renda Variável no Brasil e no exterior.",
      avatar: format("%s/static/landing/testimonials/danferc.jpg", getBaseUrl()),
    },
    {
      name: "Thiago Ferreira",
      socialMedia: {
        label: "@thiagaojf1982",
        href: "https://www.instagram.com/thiagaojf1982",
      },
      jobTitle: "",
      company: "",
      testimonial:
        "Tenho acompanhado o Edu há certo tempo em cursos abertos e pelo Insta. Tive oportunidade de fazer as duas últimas versões do curso Caixa Preta e sou assinante do Fundamentei. Me ajudou a formar uma carteira americana e moldar melhor a minha brasileira.",
    },
    {
      name: "Aline Gonçalves",
      socialMedia: {
        label: "@byalinegoncalves",
        href: "https://www.instagram.com/byalinegoncalves",
      },
      jobTitle: "Auditora Externa e Mentora de Alta Performance Sustentável",
      company: "",
      testimonial:
        "O Fundamentei é excepcional para uma abordagem estratégica de investimentos! Me apoia tanto no monitoramento quanto no aprendizado contínuo, tornando tudo intuitivo. Farol para escolhas sustentáveis e baseadas em dados de qualidade? Aprovadi$$imo!",
      avatar: format("%s/static/landing/testimonials/alinegsantoss.jpeg", getBaseUrl()),
    },
    {
      name: "Alessandro Monteiro",
      socialMedia: {
        label: "@amcavalcante__",
        href: "https://www.instagram.com/amcavalcante__",
      },
      jobTitle: "Servidor Público Federal",
      company: "Governo",
      testimonial:
        "O site Fundamentei foi crucial para meu desenvolvimento profissional e como investidor. Sua interface simples e conteúdo interativo foram fundamentais em minha jornada. Agradeço por ser uma fonte tão valiosa nos últimos anos!",
      avatar: format("%s/static/landing/testimonials/amcavalcantefa7.png", getBaseUrl()),
    },
    {
      name: "Daniel Pedrazi",
      socialMedia: {
        label: "@daniel.dvp ",
        href: "https://www.instagram.com/daniel.dvp ",
      },
      jobTitle: "Perito Criminal",
      company: "Polícia Científica de SP",
      testimonial:
        "Sabe aquele lugar onde podemos encontrar, de forma simples e resumida, todas as informações importantes sobre as empresas listadas na Bolsa? Este é o Fundamentei. Nele obtemos um panorama completo da saúde da empresa e então tomar a melhor decisão!",
      avatar: format("%s/static/landing/testimonials/danielvp199.jpeg", getBaseUrl()),
    },
    {
      name: "Fernando Assunção",
      socialMedia: {
        label: "@drfernandocesarassucao",
        href: "https://www.instagram.com/drfernandocesarassucao",
      },
      jobTitle: "Diretor de Tecnologia e Inovação",
      company: "Mais 60 Saúde",
      testimonial:
        "O Fundamentei é excelente! Com acesso fácil a análises e dados financeiros das empresas, ele fornece tudo que preciso para tomar decisões informadas e investir com confiança na bolsa. O site é uma ótima ferramenta para quem deseja investir!",
      avatar: format("%s/static/landing/testimonials/fernandonefro.jpeg", getBaseUrl()),
    },
    {
      name: "Fernando Fridlund",
      socialMedia: {
        label: "fernando.fridlund",
        href: "https://www.facebook.com/fernando.fridlund/",
      },
      jobTitle: "Geólogo",
      company: "Petrobras",
      testimonial:
        "Fundamentei é uma ferramenta excepcional até para usuários iniciantes, onde aprendemos a identificar e mensurar os fundamentos de cada empresa que é alvo de nosso interesse. Fundamentei nos ensina como é fácil atuar no mercado de renda variável.",
      avatar: format("%s/static/landing/testimonials/fridlund.png", getBaseUrl()),
    },
    {
      name: "Giovanni Petris",
      socialMedia: {
        label: "@giopetris",
        href: "https://www.instagram.com/giopetris",
      },
      jobTitle: "Desenvolvedor de Software",
      company: "Beech Valley",
      testimonial:
        "O Fundamentei é essencial para minhas decisões de investimento. O site simplifica a análise de ativos, oferece informações exclusivas e possui conteúdo incrível em vídeo e da comunidade. Sem dúvida, é o melhor site para investidores de longo prazo.",
      avatar: format("%s/static/landing/testimonials/giopetris.jpeg", getBaseUrl()),
    },
    {
      name: "Guilherme Gindri",
      socialMedia: {
        label: "@guilhermegindri",
        href: "https://www.instagram.com/guilhermegindri",
      },
      jobTitle: "@guilhermegindri",
      company: "Engenheiro Eletricista",
      testimonial:
        "O Fundamentei fez com que dissipasse uma névoa que pairava sobre a renda variável para mim. A plataforma completa e as análises em vídeo permitem que tenhamos independência na hora de investir.",
      avatar: format("%s/static/landing/testimonials/guilher_me_tal.jpeg", getBaseUrl()),
    },
    {
      name: "Humberto Faria",
      socialMedia: {
        label: "@humbertodonisetede",
        href: "https://www.instagram.com/humbertodonisetede",
      },
      jobTitle: "Aposentado",
      company: "Cemig",
      testimonial:
        "Após 3 anos batendo cabeça, sofrendo com insegurança, dúvidas, dificuldades nas análises e muita tensão, descobri o Fundamentei. Mudou tudo, pois era exatamente o que eu precisava: investir com tranquilidade, sem estresse, muita segurança e alegria.",
      avatar: format("%s/static/landing/testimonials/hdf1967.jpeg", getBaseUrl()),
    },
    {
      name: "João Marcos",
      socialMedia: {
        label: "@joaomarcosdefigueiredo",
        href: "https://www.instagram.com/joaomarcosdefigueiredo",
      },
      jobTitle: "Médico",
      company: "",
      testimonial:
        "Assino o fundamentei há anos e posso dizer que foi a melhor coisa que me aconteceu como investidor. Uma abordagem séria sobre investimentos, sem sensacionalismo e focada em adquirir conhecimento e investir no longo prazo. Obrigado!",
    },
    {
      name: "Leandro Soares",
      socialMedia: {
        label: "@leandro_silva0508",
        href: "https://www.instagram.com/leandro_silva0508",
      },
      jobTitle: "Comissário de Voo",
      company: "Azul",
      testimonial:
        "Minha experiência no Fundamentei transformou minha forma de investir! Com um layout detalhado e insights precisos, agora tomo decisões mais seguras e inteligentes para investir. Obrigado equipe fundamentei por facilitar meu modo de avaliar e investir.",
      avatar: format("%s/static/landing/testimonials/leandro.jpeg", getBaseUrl()),
    },
    {
      name: "Matheus Lima",
      socialMedia: {
        label: "@mrdlima",
        href: "https://www.instagram.com/mrdlima",
      },
      jobTitle: "Engenheiro de Vendas",
      company: "Weg",
      testimonial:
        "O Fundamentei é uma plataforma excelente que envolve informações valiosas e atualizadas de diversos ativos de renda variável. Seus principais diferenciais são a diversidade de dados e a praticidade visual como são apresentados.",
      avatar: format("%s/static/landing/testimonials/m.lima2509.png", getBaseUrl()),
    },
    {
      name: "Vinícius Marzo",
      socialMedia: {
        label: "@serenittapfp",
        href: "https://www.instagram.com/serenittapfp",
      },
      jobTitle: "Planejador Financeiro",
      company: "Serenittà",
      testimonial:
        "Sou o Vinícius Marzo, planejador financeiro pessoal da Serenittà, e sou assinante do Fundamentei desde 2019. Profissionais sérios e competentes, conseguem nos trazer informações técnicas e fundamentalista de cada balanço das empresa.",
      avatar: format("%s/static/landing/testimonials/vinicius.jpeg", getBaseUrl()),
    },
  ];
}
