import gql from "graphql-tag";

export default gql`
  query PremiumPrices {
    premiumPrices {
      id
      amount
      monthlyAmount
      formattedAmount
      formattedMonthlyAmount
      isActive
      recurringInterval
      currency
      product {
        id
        name
      }
    }
  }
`;
