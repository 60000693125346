import Avatar from "components/core/Avatar";
import getTestimonials from "components/landing/helpers/getTestimonials";
import classNames from "components/ui/classNames";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function TestimonialsSection() {
  const intl = useIntl();
  const isUsLanguage = !intl.locale.startsWith("pt-BR");
  const testimonials = getTestimonials(isUsLanguage);
  const [isShowingMore, setIsShowingMore] = useState(false);

  return (
    <section id="testimonials" className="border-t border-slate-950 bg-slate-950 px-6 py-12 lg:px-0 lg:py-20">
      <div className="mx-auto max-w-5xl pb-16 text-center">
        <h3 className="text-pretty text-4xl font-medium tracking-tighter text-white sm:text-5xl">
          <FormattedMessage defaultMessage="Veja o que nossos usuários têm a dizer" id="6a01+6" />
        </h3>
      </div>
      <section className="relative mx-auto max-w-7xl px-4 focus:outline-none sm:px-3 md:px-5">
        <ul
          className={classNames("grid grid-cols-1 gap-6 overflow-hidden", "sm:grid-cols-2", "lg:grid-cols-3 lg:gap-8", {
            "max-h-[40rem]": !isShowingMore,
          })}
        >
          {testimonials.map((node) => (
            <li key={node.name} className="max-w-full space-y-4 rounded-xl bg-slate-800/90 p-5 lg:max-w-96">
              <div className="flex items-center gap-3">
                <Avatar src={node.avatar || null} alt={node.name} size={44} className="object-cover" />

                <div className="flex flex-col">
                  <div className="inline-flex">
                    <a
                      href={node.socialMedia.href}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="font-bold leading-tight text-slate-100 hover:text-blue-300 hover:underline hover:underline-offset-2"
                    >
                      {node.name}
                    </a>
                  </div>
                  <span
                    className="max-w-52 truncate text-sm font-medium text-slate-400 lg:max-w-64"
                    title={node.jobTitle || node.socialMedia.label}
                  >
                    {node.jobTitle || node.socialMedia.label}
                  </span>
                </div>
              </div>

              <blockquote className="whitespace-pre-wrap text-slate-400">{node.testimonial}</blockquote>
            </li>
          ))}
        </ul>
        <div
          className={classNames(
            "pointer-events-none absolute inset-x-0 bottom-0 flex justify-center bg-gradient-to-t from-black pb-8 pt-32",
          )}
        >
          <button
            type="button"
            onClick={() => setIsShowingMore((isShowingMore) => !isShowingMore)}
            className={classNames(
              "pointer-events-auto relative flex h-12 items-center rounded-lg bg-slate-900 px-6 text-sm font-semibold text-white",
              "hover:bg-slate-700",
              "dark:bg-slate-700",
              "dark:hover:bg-slate-600",
            )}
          >
            {isShowingMore ? (
              <FormattedMessage defaultMessage="Ok, muito bom!" id="IcbniS" />
            ) : (
              <FormattedMessage defaultMessage="Ver mais..." id="Ljrjpw" />
            )}
          </button>
        </div>
      </section>
    </section>
  );
}
